// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anlaggningen-js": () => import("./../../../src/pages/anlaggningen.js" /* webpackChunkName: "component---src-pages-anlaggningen-js" */),
  "component---src-pages-hastarna-js": () => import("./../../../src/pages/hastarna.js" /* webpackChunkName: "component---src-pages-hastarna-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-teamet-js": () => import("./../../../src/pages/teamet.js" /* webpackChunkName: "component---src-pages-teamet-js" */),
  "component---src-pages-tjanster-js": () => import("./../../../src/pages/tjanster.js" /* webpackChunkName: "component---src-pages-tjanster-js" */),
  "component---src-templates-blog-category-view-js": () => import("./../../../src/templates/blog-category-view.js" /* webpackChunkName: "component---src-templates-blog-category-view-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-horses-js": () => import("./../../../src/templates/horses.js" /* webpackChunkName: "component---src-templates-horses-js" */)
}

